import type { TEMPLATE_METADATA } from '$types/index'

export interface MetaProps {
  type: string
  title: string
  description: string
  image: string
  url: string
  twitter_image: string
  twitter_title: string
  twitter_description: string
}

type OptionalMetaProps = Partial<MetaProps>

const defaultMetaProps: MetaProps = {
  type: 'website',
  title:
    'Lumiere | Deep video learning through interactive content | by Latitude',
  description:
    'Our AI-propelled video knowledge platform, Lumiere, helps you better understand audiences and users, to unlock the true power of your content.',
  image: '/og-lumiere.jpeg',
  url: 'https://lumiere.is',
  twitter_image: '/og-lumiere.jpeg',
  twitter_title:
    'Lumiere | Deep video learning through interactive content | by Latitude',
  twitter_description:
    'Our AI-propelled video knowledge platform, Lumiere, helps you better understand audiences and users, to unlock the true power of your content.',
}

/**
 *
 * @param items
 * @param csId case study id
 * @param csPathname case study pathname
 */
export function getCaseStudyMetaProps(
  items: any,
  csId: string,
  csPathname: string,
) {
  const item = items.find((c: any) => c.fields.id === csId)

  if (item) {
    return patchMetaProps({
      // type: item.fields.type,
      title: `${item.fields.headline}`,
      description: `${item.fields.subheadline}`,
      image: `${item.fields.imageSrcFull.fields.file.url}`,
      url: `https://lumiere.is${csPathname}`,
      twitter_image: `${item.fields.imageSrcFull.fields.file.url}`,
      twitter_title: `${item.fields.headline}`,
      twitter_description: `${item.fields.subheadline}`,
    })
  }

  return defaultMetaProps
}

export function patchMetaProps(metaProps: OptionalMetaProps): MetaProps {
  return { ...defaultMetaProps, ...metaProps }
}

export function extractMetadata(
  fields: TEMPLATE_METADATA['fields'],
  pathname: string,
) {
  const { featuredImage, ...rest } = fields
  return {
    ...rest,
    image: featuredImage.fields.file.url,
    url: `https://lumiere.is${pathname}`,
  }
}

export function getBlogMetaProps(items: any, bpId: any, bpPathname: string) {
  const item = items.find((c: any) => c.fields.id === bpId)

  if (item) {
    return patchMetaProps({
      // type: item.fields.type,
      title: `${item.fields.title}`,
      description: `${item.fields.introParagraph}`,
      image: `${item.fields.mainImage.fields.file.url}`,
      url: `https://lumiere.is${bpPathname}`,
      twitter_image: `${item.fields.mainImage.fields.file.url}`,
      twitter_title: `${item.fields.title}`,
      twitter_description: `${item.fields.introParagraph}`,
    })
  }

  return defaultMetaProps
}
